import styled from 'styled-components'
import {device} from '../../../../theme'

export const SectionWrap = styled.section ``;

export const GridWrap = styled.div `
    border-right: 1px solid #f6f2ed;
    @media (prefers-color-scheme: dark) {
        border-right: 1px solid ${props => props.theme.colorsDark.borderColor};
        // background: #121212;
    }
    display: flex;
    flex-wrap: wrap;
    margin-top: -80px;
    @media ${device.medium}{
        margin-top: -60px;
    }
    @media ${device.small}{
        margin-top: -40px !important;
    }
    
`;

export const Grid = styled.div `
    // @media (prefers-color-scheme: light) {
    //     background-color: rgb(248, 248, 248);
    // }
    background-color: #f6f2ed;
    @media (prefers-color-scheme: dark) {
        background: #121212;
    }
    max-width: 25%;
    flex-basis: 25%;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    @media ${device.large}{
        padding-left: 10px;
        padding-right: 10px;
    }
    @media ${device.medium}{
        padding-top: 60px;
        padding-bottom: 60px;
        max-width: 50%;
        flex-basis: 50%;    
    }
    @media ${device.small}{
        max-width: 100%;
        flex-basis: 100%;    
    }
    &:before,
    &:after{
        position: absolute;
        content: '';
        left: 0;
        // @media (prefers-color-scheme: light) {
        //     background: ${props => props.theme.colors.borderColor};
        // }
        @media (prefers-color-scheme: dark) {
            background: ${props => props.theme.colorsDark.borderColorTwo};
        }
        background: #fff;
    }
    &:before{
        top: 0;
        bottom: 0;
        width: 1px;
    }
    &:after{
        top: 0;
        right: 0;
        height: 1px
    }
`;