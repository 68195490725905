import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import Hero from '../containers/index-infotechno/hero'
import ClientsArea from '../containers/global/clients-area'
import ServicesArea from '../containers/about-us/services-area'
import FunFactArea from '../containers/global/funfact-area/section-two'
import VideoArea from '../containers/index-infotechno/video-area'
import CaseStudyArea from '../containers/index-infotechno/case-study-area'
import TestimonialArea from '../containers/global/testimonial-area/section-two'
import BlogArea from '../containers/index-infotechno/blog-area'
import ContactArea from '../containers/index-appointment/contact-area'

const IndexInfoTechno = ({ location }) => {
    return (
        <Layout location={location}>
            <SEO 
                title="Custom Software, Mobile Apps, Websites, Digital Products"
                description="sixvertex is a value-driven, agile software development company that builds custom software, mobile apps, websites, and digital products."
            /> 
            <Header />
            <main className="site-wrapper-reveal">
                <Hero />
                <ClientsArea />
                <ServicesArea />
                <VideoArea />

                <FunFactArea />
                <CaseStudyArea />
                <TestimonialArea />
                <BlogArea />
                <ContactArea />
            </main>
            <Footer />
        </Layout>
    )
}

export default IndexInfoTechno; 